<template lang="pug">
div
  om-dropdown-input(
    v-if="subPath === 'tab'"
    label="border"
    :property="getPath('border.selectedBorder')"
    :items="selectedBorderMin"
    :typeOfComponent="typeOfComponent"
  )
  om-dropdown-input(
    v-if="subPath !== 'tab'"
    label="border"
    :property="getPath('border.selectedBorder')"
    :items="selectedBorder"
    :typeOfComponent="typeOfComponent"
  )
  template(v-if="selectedBorderValue !== 'no'")
    om-dropdown-input(
      label="borderType"
      :property="getPath('border.type')"
      :items="type"
      :typeOfComponent="typeOfComponent"
    )
    om-color-input(
      label="borderColor"
      :property="getPath('border.color')"
      :typeOfComponent="typeOfComponent"
    )
    .mt-4
      om-range-input(
        label="width"
        :property="getPath('border.width')"
        :min="0"
        :max="40"
        :step="1"
        :typeOfComponent="typeOfComponent"
      )
  corner-radius(:subPath="subPath")
  om-dropdown-input(
    label="shadow"
    :property="getPath('shadow.type')"
    :items="shadowType"
    :typeOfComponent="typeOfComponent"
  )
  om-color-input(
    v-if="shadowTypeValue !== 'none'"
    label="shadowColor"
    :property="getPath('shadow.color')"
    :typeOfComponent="typeOfComponent"
  )
</template>

<script>
  import CornerRadius from '@/editor/components/sidebar/components/CornerRadius.vue';
  import itemMixin from '../../../mixins/item';

  export default {
    components: { CornerRadius },
    mixins: [itemMixin],
    props: {
      propsProperty: { type: String },
      subPath: { type: String, default: 'style' },
      typeOfComponent: { type: String, default: 'global' },
    },
    data() {
      return {
        show: false,
        selectedBorderMin: [
          { key: 'none', value: 'no' },
          { key: 'full', value: 'full' },
        ],
        selectedBorder: [
          { key: 'none', value: 'no' },
          { key: 'full', value: 'full' },
          { key: 'top', value: 'top' },
          { key: 'bottom', value: 'bottom' },
          { key: 'left', value: 'left' },
          { key: 'right', value: 'right' },
          { key: 'topBottom', value: 'top-bottom' },
        ],
        type: [
          { key: 'solid', value: 'solid' },
          { key: 'dashed', value: 'dashed' },
          { key: 'dotted', value: 'dotted' },
        ],
        shadowType: [
          { key: 'none', value: 'none' },
          { key: 'normal', value: 'normal' },
          { key: 'medium', value: 'medium' },
          { key: 'strong', value: 'large' },
        ],
      };
    },
    computed: {
      isTab() {
        return this.getValueOf(this.getPath('data.type')) === 'tab';
      },
      shadowColor() {
        return this.getValueOf(this.getPath('shadow.type'), '#000') || '#000';
      },
      selectedBorderValue() {
        return this.getValueOf(this.getPath('border.selectedBorder'), 'no') || 'no';
      },
      shadowTypeValue() {
        return this.getValueOf(this.getPath('shadow.type'), 'none') || 'none';
      },
    },
  };
</script>
